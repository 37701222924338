
  export default {
  name: "OverJobs",
  props: {
      id: {
          default: '',
          type: String,
      },
  },
  data() {
      return {

      }
    },
    render() {
      const slot = this.$slots.default
      //  return slot.text.replace(/,/g, "")
      return slot
    },
  }
